import { getLocaleFullName } from '../useInvoice'
import { getSumOfTotalPayment } from '../useInvoicePayment'

export function showPopupInvoiceSendEmail(id = 'id-invoice-popup-send-email') {
  this.$bvModal.show(id)
}

export function hidePopupInvoiceSendEmail(id = 'id-invoice-popup-send-email') {
  this.$bvModal.hide(id)
}

export function createEmailsData(invoices) {
  if (!invoices?.length) {
    this.emailsData = []
    return
  }

  const result = []
  invoices.forEach(invoice => {
    result.push({ ...invoice })
  })

  this.emailsData = result
}

export function getInvoiceDetailForSendEmail(invoices) {
  const result = []

  if (!invoices || !invoices.length) return result

  invoices.forEach(invoice => {
    let name = invoice.customer
      ? getLocaleFullName(invoice.customer)
      : invoice.customerName || ''
    if (!name) {
      name = invoice.receiptNameAffterSign || ''
    }

    const companyEmail = invoice.company ? invoice.company.mail : ''
    let email = invoice.customer ? invoice.customer.emailAddress : ''
    if (!email) {
      email = companyEmail
    }
    if (!email) {
      email = invoice.receiptMailAffterSign
        ? invoice.receiptMailAffterSign.join(';')
        : ''
    }

    let cc = ''
    if (email !== companyEmail) {
      cc = companyEmail
    }
    if (!cc) {
      cc = invoice.ccReceiptMailAffterSign
        ? invoice.ccReceiptMailAffterSign.join(';')
        : ''
    }

    result.push({
      id: invoice.id,
      name,
      email,
      cc,
      bcc: invoice.bccReceiptMailAffterSign
        ? invoice.bccReceiptMailAffterSign.join(';')
        : '',
      invoiceNumber: invoice.invoiceNumber,
      formSerial: `${invoice.form || ''}${invoice.serial || ''}`,
      emailStatus: invoice.emailStatus,
      totalPayment: getSumOfTotalPayment(invoice.invoiceItems),
    })
  })

  return result
}

export function getInvoiceDetailForSendEmailAfterSign(invoices) {
  const result = []

  if (!invoices || !invoices.length) return result

  invoices.forEach(invoice => {
    let name = invoice.receiptNameAffterSign || ''
    if (!name) {
      name = invoice.customer
        ? getLocaleFullName(invoice.customer)
        : invoice.customerName || ''
    }

    let email = invoice.receiptMailAffterSign
      ? invoice.receiptMailAffterSign.join(';')
      : ''
    if (!email) {
      email = invoice.customer ? invoice.customer.emailAddress : ''
    }
    if (!email) {
      email = invoice.company ? invoice.company.mail : ''
    }

    result.push({
      id: invoice.id,
      name,
      email,
      cc: invoice.ccReceiptMailAffterSign
        ? invoice.ccReceiptMailAffterSign.join(';')
        : '',
      bcc: invoice.bccReceiptMailAffterSign
        ? invoice.bccReceiptMailAffterSign.join(';')
        : '',
      invoiceNumber: invoice.invoiceNumber,
      formSerial: `${invoice.form || ''}${invoice.serial || ''}`,
      emailStatus: invoice.emailStatus,
      totalPayment: getSumOfTotalPayment(invoice.invoiceItems),
    })
  })

  return result
}

function parseEmailsStringToArray(emails) {
  if (!emails) return []
  // removes all whitespace characters & all trailing semicolons:
  const str = emails.replace(/\s+/g, '').replace(/;+$/, '')
  if (!str) return []

  // convert string to array:
  return str.split(';')
}

export function onFlowSignInvoiceWithSendEmailOption() {
  const invoiceItem = this.emailsData.map(item => ({
    id: item.id,
    isSendEmailAffterSign: this.isAutoSendEmail,
    receiptNameAffterSign: item.name,
    receiptMailAffterSign: parseEmailsStringToArray(item.email),
    ccReceiptMailAffterSign: parseEmailsStringToArray(item.cc),
    bccReceiptMailAffterSign: parseEmailsStringToArray(item.bcc),
  }))
  const payload = {
    creator: JSON.parse(localStorage.getItem('employeeData'))?.id,
    invoiceItem,
  }

  this.$emit('onSignInvoiceWithSendEmailOption', payload)
  this.hidePopupInvoiceSendEmail(this.idPopupSendEmail)
}

export async function onFlowSendEmail() {
  const invoiceEmailItems = this.emailsData.map(el => ({
    id: el.id,
    recipientName: el.name,
    recipients: parseEmailsStringToArray(el.email),
    cc: parseEmailsStringToArray(el.cc),
    bcc: parseEmailsStringToArray(el.bcc),
  }))

  const res = await this.$store.dispatch('app-invoice/sendMail', {
    invoiceEmailItems,
  })
  if (res) {
    this.$emit('sendEmailSuccess')
    this.hidePopupInvoiceSendEmail(this.idPopupSendEmail)
    this.showToastSuccess('Gửi email thành công!')
  }
}

export async function onSeparateFlowSendEmailAndSignInvoice(event) {
  event.preventDefault()

  const valid = await this.$refs.refFormObserver.validate()
  if (!valid) return

  if (this.useWhenSignInvoice) {
    // flow sign invoice with send email option
    this.onFlowSignInvoiceWithSendEmailOption()
    return
  }
  // flow sending email only
  this.onFlowSendEmail()
}

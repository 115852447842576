import { ref, computed } from '@vue/composition-api'

export function showPopupChooseTicket() {
  this.$bvModal.show('id-invoice-popup-choose-ticket')
}

export function getTicketDescription(ticket, showTicketNumber) {
  let result = ticket.ticketTrips

  if (showTicketNumber) {
    const moreInfo = ticket.ticketNumber || ticket.bookingCode || ''
    if (moreInfo) {
      result += ` ${moreInfo}`
    }
  }

  return result
}

export function getInvoiceItemsFromTickets(
  tickets,
  isCombineTickets,
  showTicketNumber,
) {
  const ticketCount = tickets.length
  const needCombine = isCombineTickets && ticketCount > 1

  if (needCombine) {
    const description = getTicketDescription(tickets[0], false)

    const ticketIds = []
    let collectionFee = 0
    let amount = 0

    tickets.forEach(ticket => {
      ticketIds.push(ticket.id)
      collectionFee += ticket.collectionFee
      amount += ticket.priceTicket + ticket.tax
    })

    const isFlightTypeDomestic = tickets[0].flightType === 'Domestic'
    const vatRate = isFlightTypeDomestic ? 10 : 0
    const vatRateValue = vatRate / 100
    const unitPriceFloat = amount / ((1 + vatRateValue) * ticketCount)
    const unitPrice = Math.round(unitPriceFloat)
    const beforeTaxAmount = unitPrice * ticketCount
    const vatAmount = amount - beforeTaxAmount

    return [
      {
        readonly: false,
        characteristic: 'SERVICE',
        description,
        unit: 'Vé',
        quantity: ticketCount,
        unitPrice,
        beforeTaxAmount,
        vatRate,
        vatAmount,
        amount,

        // additional:
        isFlightTypeDomestic,
        ticketCount,
        isTicket: true,
        ticketIds,
        collectionFee,

        forceKeepAmountValueRightAfterInsert: true,
      },
    ]
  }

  const invoiceItems = tickets.map(ticket => {
    const description = getTicketDescription(ticket, showTicketNumber)

    const ticketIds = [ticket.id]
    const collectionFee = ticket.collectionFee

    const amount = ticket.priceTicket + ticket.tax
    const isFlightTypeDomestic = ticket.flightType === 'Domestic'
    const vatRate = isFlightTypeDomestic ? 10 : 0
    const vatRateValue = vatRate / 100
    const unitPriceFloat = amount / (1 + vatRateValue)
    const unitPrice = Math.round(unitPriceFloat)
    const beforeTaxAmount = unitPrice
    const vatAmount = amount - beforeTaxAmount

    return {
      readonly: false,
      characteristic: 'SERVICE',
      description,
      unit: 'Vé',
      quantity: 1,
      unitPrice: beforeTaxAmount,
      beforeTaxAmount,
      vatRate,
      vatAmount,
      amount,

      // additional:
      isFlightTypeDomestic,
      ticketCount: 1,
      isTicket: true,
      ticketIds,
      collectionFee,

      forceKeepAmountValueRightAfterInsert: true,
    }
  })

  return invoiceItems
}

export function emitTicketsFromSaleReportToInvoice() {
  if (!this.selectedTickets.length) return

  const invoiceItems = getInvoiceItemsFromTickets(
    this.selectedTickets,
    this.isCombineTickets,
    this.showTicketNumber,
  )
  this.$emit('onSelectTickets', invoiceItems)
}

export function onHandleSelectAllOrSelectNone() {
  if (this.disabledSelectAll) return

  if (this.isSelectAll) {
    this.selectedIds = []
    this.selectedTickets = []
  } else {
    this.selectedIds = [...this.idsCanSelect]
    this.selectedTickets = [...this.itemsCanSelect]
  }
}

export function useChooseTicket() {
  const idsCanSelect = ref([])
  const itemsCanSelect = ref([])
  const maxSelectionCount = computed(() => idsCanSelect.value.length)
  const isCombineTickets = ref(false)
  const showTicketNumber = ref(true)

  return {
    idsCanSelect,
    itemsCanSelect,
    maxSelectionCount,
    isCombineTickets,
    showTicketNumber,
  }
}

export function isAlreadySelected(ticket) {
  return this.previousSelectedTicketIds.includes(ticket.id)
}

const loginAgencyCode = JSON.parse(localStorage.getItem('employeeData'))?.agency?.agencyCode || ''

export function showCustome(ticket) {
  return loginAgencyCode && loginAgencyCode === ticket.agency
}

export function getCustomerName(ticket) {
  return ticket?.customerName
}

export function getAgencyName(ticket) {
  return ticket?.agencyName
}

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('InvoiceListFilters', {
    ref: "invoiceListFilters",
    attrs: {
      "search-keywords": _vm.searchKeywords,
      "fixed-invoice-status": _vm.fixedInvoiceStatus,
      "fixed-invoice-types": _vm.fixedInvoiceTypes
    },
    on: {
      "update:searchKeywords": function updateSearchKeywords($event) {
        _vm.searchKeywords = $event;
      },
      "update:search-keywords": function updateSearchKeywords($event) {
        _vm.searchKeywords = $event;
      },
      "refetch-data-with-filters": _vm.refetchData,
      "refetch-data-without-filters": _vm.clearFilter
    }
  }), _c('b-card', {
    staticStyle: {
      "max-height": "70vh"
    },
    attrs: {
      "no-body": ""
    }
  }, [_c('div', [_c('b-row', {
    staticClass: "m-1"
  }, [_c('b-col', {
    staticClass: "px-0 mr-md-1",
    attrs: {
      "cols": "4",
      "md": "auto"
    }
  }, [_c('v-select', {
    staticClass: "per-page-selector d-inline-block mx-50",
    staticStyle: {
      "font-size": "1rem"
    },
    attrs: {
      "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
      "options": _vm.sizePerPageOptions,
      "clearable": false
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.label) + " ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.label) + " ")])];
      }
    }]),
    model: {
      value: _vm.sizePerPage,
      callback: function callback($$v) {
        _vm.sizePerPage = $$v;
      },
      expression: "sizePerPage"
    }
  })], 1), _vm.enabledActions ? _c('b-col', {
    staticClass: "d-flex justify-content-end px-50",
    attrs: {
      "cols": "8",
      "md": "auto",
      "order-md": "3"
    }
  }, [_c('div', {
    staticClass: "d-none d-lg-flex"
  }, [_c('b-button', {
    staticClass: "mr-1 px-lg-1 px-md-75",
    staticStyle: {
      "min-width": "120px"
    },
    attrs: {
      "variant": "info",
      "to": {
        name: 'apps-invoicesOld-add'
      }
    }
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "icon": "PlusSquareIcon",
      "size": "16"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('invoice.title')) + " ")], 1)]), _c('b-button', {
    staticClass: "px-lg-1 px-md-75",
    staticStyle: {
      "min-width": "120px"
    },
    attrs: {
      "variant": "info"
    },
    on: {
      "click": function click($event) {
        return _vm.confirmExport();
      }
    }
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "icon": "DownloadIcon",
      "size": "16"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('export')) + " ")], 1)])], 1), _vm.enabledActions ? _c('div', {
    staticClass: "d-block d-lg-none"
  }, [_c('b-dropdown', {
    staticClass: "m-lg-2",
    attrs: {
      "variant": "info",
      "boundary": "window",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "MenuIcon",
            "size": "16"
          }
        }), _c('span', {
          staticClass: "pl-50"
        }, [_vm._v(_vm._s(_vm.$t('moreDropdownTitle')))])];
      },
      proxy: true
    }], null, false, 4008091105)
  }, [_c('b-dropdown-item', {
    attrs: {
      "to": {
        name: 'apps-invoicesOld-add'
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.moreDropdown.add')) + " ")]), _c('b-dropdown-item', {
    on: {
      "click": function click($event) {
        return _vm.confirmExport();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.moreDropdown.export')) + " ")])], 1)], 1) : _vm._e()]) : _vm._e(), _c('b-col', {
    staticClass: "mt-1 mt-md-0 px-50 flex-grow-1",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "w-100 d-flex align-items-center"
  }, [_c('b-input-group', {
    staticClass: "w-100",
    class: {
      'mr-md-1': _vm.enabledActions
    },
    attrs: {
      "size": "md"
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center border rounded-left px-1 bg-light-info"
        }, [_c('feather-icon', {
          attrs: {
            "icon": "SearchIcon",
            "size": "20"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-form-input', {
    attrs: {
      "type": "search",
      "placeholder": _vm.$t('invoice.phSearch'),
      "reduce": function reduce(val) {
        return val.value;
      },
      "debounce": "500",
      "trim": ""
    },
    on: {
      "input": function input($event) {
        return _vm.onDebounceSearch($event, _vm.onRefetchData);
      }
    },
    model: {
      value: _vm.searchKeywords,
      callback: function callback($$v) {
        _vm.searchKeywords = $$v;
      },
      expression: "searchKeywords"
    }
  })], 1)], 1)])], 1), _c('b-row', {
    staticClass: "font-weight-bold text-white mx-0 py-25 py-md-75",
    staticStyle: {
      "background-color": "#92CBFD"
    }
  }, [_c('b-col', {
    staticClass: "d-flex align-items-center px-0 mx-1 mx-md-2",
    attrs: {
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "d-inline-block"
  }, [_vm.notSelect ? [_vm._v(" " + _vm._s(_vm.$tc('invoice.selectionCount', 0)) + " ")] : _vm.selectionCount === 1 ? [_vm._v(" " + _vm._s(_vm.$tc('invoice.selectionCount', 1)) + " ")] : [_vm._v(" " + _vm._s(_vm.$tc('invoice.selectionCount', _vm.selectionCount, {
    count: _vm.selectionCount
  })) + " ")]], 2)]), _vm.enabledActions ? _c('b-col', {
    staticClass: "d-flex flex-grow-1 justify-content-end align-items-center px-0 mx-1 mx-md-2",
    attrs: {
      "md": "auto"
    }
  }, [!_vm.roleMama ? _c('span', {
    class: _vm.notSelect ? 'text-secondary cursor-not-allowed' : 'hover-text-primary cursor-pointer',
    on: {
      "click": function click($event) {
        return _vm.onHandleSendToApproveMultipleInvoices(_vm.selectedInvoices);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.btn.sendToApprove')) + " ")]) : _vm._e(), _vm.roleMama ? [!_vm.isFixedInvoiceStatusDraftOrApproved ? [_c('span', {
    staticClass: "ml-50 ml-md-1",
    class: _vm.notSelect ? 'text-secondary cursor-not-allowed' : 'hover-text-primary cursor-pointer',
    on: {
      "click": function click($event) {
        return _vm.onHandleApproveMultipleInvoices(_vm.selectedInvoices);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.btn.approve')) + " ")]), _c('span', {
    staticClass: "ml-50 ml-md-1",
    class: _vm.notSelect ? 'text-secondary cursor-not-allowed' : 'hover-text-primary cursor-pointer',
    on: {
      "click": function click($event) {
        return _vm.onHandleApproveMultipleInvoices(_vm.selectedInvoices, false);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.btn.disapprove')) + " ")])] : _vm._e(), !_vm.isFixedInvoiceStatusAwaitingApproval ? [_c('span', {
    staticClass: "ml-1 ml-md-2",
    class: _vm.notSelect ? 'text-secondary cursor-not-allowed' : 'hover-text-primary cursor-pointer',
    on: {
      "click": function click($event) {
        return _vm.onHandleSignMultipleInvoices(_vm.selectedInvoices);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.btn.sign')) + " ")]), _c('span', {
    staticClass: "ml-50 ml-md-1",
    class: _vm.notSelect ? 'text-secondary cursor-not-allowed' : 'hover-text-primary cursor-pointer',
    on: {
      "click": function click($event) {
        return _vm.onHandleRefuseSignMultipleInvoices(_vm.selectedInvoices);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.btn.refuseSign')) + " ")])] : _vm._e()] : _vm._e(), _c('span', {
    staticClass: "ml-1 ml-md-2",
    class: _vm.notSelect ? 'text-secondary cursor-not-allowed' : 'hover-text-primary cursor-pointer',
    on: {
      "click": function click($event) {
        return _vm.onHandleSendEmailMultipleInvoices(_vm.selectedInvoices);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.btn.sendEmail')) + " ")])], 2) : _vm._e()], 1)], 1), _c('b-overlay', {
    attrs: {
      "show": _vm.loading,
      "rounded": "sm",
      "no-fade": "",
      "variant": "transparent",
      "opacity": 0.5
    }
  }, [_c('b-table', {
    ref: "refInvoiceListTable",
    staticClass: "position-relative",
    staticStyle: {
      "max-height": "45vh"
    },
    attrs: {
      "sticky-header": "",
      "responsive": "",
      "show-empty": "",
      "bordered": "",
      "primary-key": "id",
      "items": _vm.fetchInvoices,
      "fields": _vm.tableColumns,
      "sort-by": _vm.sortBy,
      "empty-text": _vm.$t('noRecordFund'),
      "sort-desc": _vm.isSortDirDesc
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumns, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn(data) {
          return [_c('div', {
            key: column.label,
            staticClass: "text-dark"
          }, [data.label === 'checkbox' ? _c('div', {
            on: {
              "click": function click($event) {
                $event.preventDefault();
                $event.stopPropagation();
                return _vm.onHandleSelectAllOrSelectNone();
              }
            }
          }, [_c('b-form-checkbox', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: !_vm.showCheckboxSelectAll,
              expression: "!showCheckboxSelectAll"
            }],
            staticClass: "mr-1 pl-0",
            attrs: {
              "indeterminate": true,
              "inline": ""
            }
          }), _c('b-form-checkbox', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: _vm.showCheckboxSelectAll,
              expression: "showCheckboxSelectAll"
            }],
            staticClass: "mr-1 pl-0",
            attrs: {
              "inline": "",
              "checked": _vm.isSelectAll,
              "disabled": _vm.isAlreadySelectAll
            }
          })], 1) : data.label === 'emailStatus' ? _c('div', {
            style: _vm.$i18n.locale === 'vi' ? 'width: 82px;' : 'width: 65px;'
          }, [_vm._v(" " + _vm._s(_vm.$t('invoice.columns.emailStatus')) + " ")]) : _c('span', {
            staticClass: "text-nowrap"
          }, [_vm._v(" " + _vm._s(_vm.$t("invoice.columns.".concat(data.label))) + " ")])])];
        }
      };
    }), {
      key: "cell(checkbox)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('b-form-checkbox', {
          staticClass: "mr-1 mt-50 pl-0",
          attrs: {
            "name": "check-box",
            "inline": "",
            "disabled": _vm.isAlreadySelected(item),
            "checked": _vm.isAlreadySelected(item) || _vm.isChecked(item.id)
          },
          on: {
            "change": function change($event) {
              return _vm.toggleSelectItem(item);
            }
          }
        })];
      }
    }, {
      key: "cell(invoiceNumber)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm.actions.canViewDetail(item.invoiceType, item.status) ? _c('b-link', {
          staticClass: "font-weight-bold d-block text-nowrap text-info pb-0",
          attrs: {
            "to": {
              name: 'apps-invoicesOld-view',
              params: {
                id: item.id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(item.invoiceNumber) + " ")]) : _c('div', {
          staticClass: "text-nowrap pb-0"
        }, [_vm._v(" " + _vm._s(item.invoiceNumber) + " ")])];
      }
    }, {
      key: "cell(publicDate)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "font-weight-bold text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.getDate(item.publicDate)) + " ")])];
      }
    }, {
      key: "cell(customer)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('div', [_c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(item.company ? item.company.name : item.companyName) + " ")]), _c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(item.customer ? _vm.getLocaleFullName(item.customer) : item.customerName) + " ")]), _c('div', [_vm._v(" " + _vm._s(_vm.$t('invoice.taxCode')) + ": " + _vm._s(item.taxCode) + " ")]), _c('div', [_vm._v(" " + _vm._s(item.company ? item.company.address : item.address) + " ")])])];
      }
    }, {
      key: "cell(totalPayment)",
      fn: function fn(_ref5) {
        var invoiceItems = _ref5.item.invoiceItems;
        return [_c('div', {
          staticClass: "font-weight-bold text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.getSumOfTotalPayment(invoiceItems))) + " ")])];
      }
    }, {
      key: "cell(totalAmountBeforeTax)",
      fn: function fn(_ref6) {
        var invoiceItems = _ref6.item.invoiceItems;
        return [_c('div', {
          staticClass: "font-weight-bold text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.getTotalAmountBeforeTax(invoiceItems))) + " ")])];
      }
    }, {
      key: "cell(totalVat)",
      fn: function fn(_ref7) {
        var invoiceItems = _ref7.item.invoiceItems;
        return [_c('div', {
          staticClass: "font-weight-bold text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.getTotalVat(invoiceItems))) + " ")])];
      }
    }, {
      key: "cell(collectionFee)",
      fn: function fn(_ref8) {
        var invoiceItems = _ref8.item.invoiceItems;
        return [_c('div', {
          staticClass: "font-weight-bold text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.getTotalCollectionFee(invoiceItems))) + " ")])];
      }
    }, {
      key: "cell(taCode)",
      fn: function fn(_ref9) {
        var codeCQT = _ref9.item.codeCQT;
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(codeCQT) + " ")])];
      }
    }, {
      key: "cell(type)",
      fn: function fn(_ref10) {
        var invoiceType = _ref10.item.invoiceType;
        return [_c('b-badge', {
          staticClass: "badge-glow",
          attrs: {
            "variant": "info"
          }
        }, [_vm._v(" " + _vm._s(_vm.getLocaleLabelOfInvoiceType(invoiceType)) + " ")])];
      }
    }, {
      key: "cell(status)",
      fn: function fn(_ref11) {
        var status = _ref11.item.status;
        return [_c('b-badge', {
          staticClass: "badge-glow",
          attrs: {
            "variant": "info"
          }
        }, [_vm._v(" " + _vm._s(_vm.getLocaleLabelOfInvoiceStatus(status)) + " ")])];
      }
    }, {
      key: "cell(emailStatus)",
      fn: function fn(_ref12) {
        var emailStatus = _ref12.item.emailStatus;
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(emailStatus ? _vm.$t('invoice.emailStatusOptions.sent') : _vm.$t('invoice.emailStatusOptions.notSent')) + " ")])];
      }
    }, {
      key: "cell(errNoti)",
      fn: function fn(_ref13) {
        var item = _ref13.item;
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(item.isErrorNotification ? 'Có thông báo sai sót' : '') + " ")])];
      }
    }, {
      key: "cell(createdAt)",
      fn: function fn(_ref14) {
        var _ref14$item = _ref14.item,
          createdAt = _ref14$item.createdAt,
          createdBy = _ref14$item.createdBy;
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.dateTime(createdAt)) + " ")]), _c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.getLocaleFullName(createdBy)) + " ")])];
      }
    }, {
      key: "cell(updatedAt)",
      fn: function fn(_ref15) {
        var _ref15$item = _ref15.item,
          updatedAt = _ref15$item.updatedAt,
          updatedBy = _ref15$item.updatedBy;
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.dateTime(updatedAt)) + " ")]), _c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.getLocaleFullName(updatedBy)) + " ")])];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(_ref16) {
        var index = _ref16.index,
          item = _ref16.item;
        return [_vm.actions.hasActions(item.invoiceType, item.status) ? _c('div', {
          staticClass: " text-right text-nowrap"
        }, [_vm.actions.canSendEmail(item.invoiceType, item.status) ? [_c('feather-icon', {
          staticClass: "text-warning cursor-pointer mr-50",
          attrs: {
            "id": "invoice-row-".concat(item.id, "-send-icon"),
            "icon": "SendIcon",
            "size": "16"
          },
          on: {
            "click": function click($event) {
              return _vm.onHandleSendEmailMultipleInvoices([item]);
            }
          }
        }), _c('b-tooltip', {
          staticClass: "cursor-pointer",
          attrs: {
            "title": _vm.$t('invoice.sendByEmail'),
            "target": "invoice-row-".concat(item.id, "-send-icon")
          }
        })] : _vm._e(), _vm.actions.canPreview(item.invoiceType, item.status) ? _c('b-link', {
          staticClass: "text-info",
          on: {
            "click": function click($event) {
              return _vm.onPreviewByItem(item);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "cursor-pointer mr-50",
          attrs: {
            "id": "invoice-row-".concat(item.id, "-preview-icon"),
            "icon": "EyeIcon",
            "size": "16"
          }
        }), _c('b-tooltip', {
          attrs: {
            "title": _vm.$t('invoice.preview'),
            "target": "invoice-row-".concat(item.id, "-preview-icon")
          }
        })], 1) : _vm._e(), _c('b-dropdown', {
          attrs: {
            "variant": "link",
            "toggle-class": "p-0",
            "no-caret": "",
            "right": _vm.$store.state.appConfig.isRTL
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('feather-icon', {
                staticClass: "align-middle text-body",
                attrs: {
                  "icon": "MoreVerticalIcon",
                  "size": "16"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [!_vm.roleMama && _vm.actions.canSendToApprove(item.invoiceType, item.status) ? _c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.onHandleSendToApproveMultipleInvoices([item]);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "ChevronsRightIcon"
          }
        }), _c('span', {
          staticClass: "align-middle ml-50"
        }, [_vm._v(_vm._s(_vm.$t('invoice.btn.sendToApprove')))])], 1) : _vm._e(), _vm.roleMama && !_vm.isFixedInvoiceStatusDraftOrApproved && _vm.actions.canApproveOrDisapprove(item.invoiceType, item.status) ? [_c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.onHandleApproveMultipleInvoices([item]);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "PlusSquareIcon"
          }
        }), _c('span', {
          staticClass: "align-middle ml-50"
        }, [_vm._v(_vm._s(_vm.$t('invoice.btn.approve')))])], 1), _c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.onHandleApproveMultipleInvoices([item], false);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "MinusSquareIcon"
          }
        }), _c('span', {
          staticClass: "align-middle ml-50"
        }, [_vm._v(_vm._s(_vm.$t('invoice.btn.disapprove')))])], 1)] : _vm._e(), _vm.roleMama && !_vm.isFixedInvoiceStatusAwaitingApproval && _vm.actions.canSignOrRefuseSign(item.invoiceType, item.status) ? [_c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.onHandleSignMultipleInvoices([item]);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "ZapIcon"
          }
        }), _c('span', {
          staticClass: "align-middle ml-50"
        }, [_vm._v(_vm._s(_vm.$t('invoice.btn.sign')))])], 1), _c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.onHandleRefuseSignMultipleInvoices([item]);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "ZapOffIcon"
          }
        }), _c('span', {
          staticClass: "align-middle ml-50"
        }, [_vm._v(_vm._s(_vm.$t('invoice.btn.refuseSign')))])], 1)] : _vm._e(), _vm.actions.canViewDetail(item.invoiceType, item.status) ? _c('b-dropdown-item', {
          attrs: {
            "to": {
              name: 'apps-invoicesOld-view',
              params: {
                id: item.id
              }
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "BookOpenIcon"
          }
        }), _c('span', {
          staticClass: "align-middle ml-50"
        }, [_vm._v(_vm._s(_vm.$t('Detail')))])], 1) : _vm._e(), _vm.actions.canUpdate(item.invoiceType, item.status) ? _c('b-dropdown-item', {
          attrs: {
            "to": {
              name: 'apps-invoicesOld-update',
              params: {
                id: item.id
              }
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EditIcon"
          }
        }), _c('span', {
          staticClass: "align-middle ml-50"
        }, [_vm._v(_vm._s(_vm.$t('update')))])], 1) : _vm._e(), _vm.actions.canAdjust(item.invoiceType, item.status) ? _c('b-dropdown-item', {
          attrs: {
            "to": {
              name: 'apps-invoicesOld-adjust',
              params: {
                id: item.id
              }
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "Edit3Icon"
          }
        }), _c('span', {
          staticClass: "align-middle ml-50"
        }, [_vm._v(_vm._s(_vm.$t('adjust')))])], 1) : _vm._e(), _vm.actions.canReplace(item.invoiceType, item.status) ? _c('b-dropdown-item', {
          attrs: {
            "to": {
              name: 'apps-invoicesOld-replace',
              params: {
                id: item.id
              }
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "RepeatIcon"
          }
        }), _c('span', {
          staticClass: "align-middle ml-50"
        }, [_vm._v(_vm._s(_vm.$t('replace')))])], 1) : _vm._e(), _vm.roleMama && _vm.actions.canCancel(item.invoiceType, item.status) ? _c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.onShowPopupCancelInvoice(index, item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "XSquareIcon"
          }
        }), _c('span', {
          staticClass: "align-middle ml-50"
        }, [_vm._v(_vm._s(_vm.$t('invoice.btn.cancelInvoice')))])], 1) : _vm._e()], 2)], 2) : _vm._e()];
      }
    }], null, true)
  })], 1), _c('div', {
    staticClass: "mx-2 mb-2"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "d-flex align-items-center justify-content-center",
    attrs: {
      "cols": "12",
      "md": "2"
    }
  }, [_c('span', {
    staticClass: "text-muted"
  }, [_vm._v(" " + _vm._s(_vm.dataMeta.from) + " " + _vm._s(_vm.$t('paginationText.to')) + " " + _vm._s(_vm.dataMeta.to) + " " + _vm._s(_vm.$t('paginationText.outOf')) + " " + _vm._s(_vm.dataMeta.of) + " " + _vm._s(_vm.$t('paginationText.items')) + " ")])]), _c('b-col', {
    staticClass: "d-flex align-items-center justify-content-center",
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('b-pagination', {
    staticClass: "mb-0 mt-1 mt-sm-0",
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.sizePerPage,
      "first-number": "",
      "last-number": "",
      "prev-class": "prev-item",
      "next-class": "next-item"
    },
    scopedSlots: _vm._u([{
      key: "prev-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronLeftIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }, {
      key: "next-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronRightIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1)], 1), _vm.enabledActions ? [_c('InvoicePopupSendEmail', {
    attrs: {
      "id-popup-send-email": _vm.idPopupSendEmail,
      "use-when-sign-invoice": _vm.useWhenSignMultipleInvoices,
      "auto-send-email": _vm.autoSendEmail,
      "invoices": _vm.dataSendEmailMultipleInvoices
    },
    on: {
      "update:autoSendEmail": function updateAutoSendEmail($event) {
        _vm.autoSendEmail = $event;
      },
      "update:auto-send-email": function updateAutoSendEmail($event) {
        _vm.autoSendEmail = $event;
      },
      "hidden": function hidden($event) {
        _vm.useWhenSignMultipleInvoices = false;
      },
      "sendEmailSuccess": function sendEmailSuccess($event) {
        return _vm.updateListViewAfterSendEmailMultipleInvoices();
      },
      "onSignInvoiceWithSendEmailOption": _vm.onSignMultipleInvoices,
      "showPreview": _vm.onPreviewById
    }
  }), _c('InvoicePopupCancel', {
    attrs: {
      "id-popup-cancel": _vm.idPopupCancel,
      "invoice-detail": _vm.invoiceDetailForCancel
    },
    on: {
      "cancelInvoiceSuccess": _vm.updateListViewAfterCancelInvoice
    }
  }), _c('InvoicePopupPreview', {
    attrs: {
      "id-popup-preview": _vm.idPreviewInvoice,
      "preview-data": _vm.previewData
    }
  }), _c('InvoicePopupPreviewIssuePdf', {
    attrs: {
      "id-popup-preview-issue-pdf": _vm.idPreviewPdf,
      "pdf-url": _vm.pdfUrl
    }
  })] : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <b-modal
    id="id-invoice-popup-list"
    size="xl"
    centered
    header-bg-variant="light-info"
    body-class="p-1"
    footer-class="justify-content-between"
    title-class="h2 font-weight-bolder text-dark"
    :title="popupTitle"
    :ok-title="$t('invoice.continue')"
    :ok-disabled="selectionCount < 1"
    :cancel-title="$t('cancel')"
    @ok="onSelectInvoices"
  >
    <InvoiceList
      ref="invoiceList"
      popup-id-sufix="popup-invoice-list"
      :enabled-actions="false"
      :fixed-invoice-status="fixedInvoiceStatus"
      :fixed-invoice-types="fixedInvoiceTypes"
      :update-selection-count="true"
      :previous-selected-ids="previousSelectedIds"
      @updateSelectionCount="val => selectionCount = val"
    />
  </b-modal>
</template>

<script>
import {
  ref,
} from '@vue/composition-api'

import InvoiceList from '../invoice-list/InvoiceList.vue'

export default {
  components: {
    InvoiceList,
  },

  props: {
    popupTitle: {
      type: String,
      default: '',
    },
    fixedInvoiceTypes: {
      type: Array,
      default: undefined,
    },
    fixedInvoiceStatus: {
      type: Array,
      default: undefined,
    },
    previousSelectedIds: {
      type: Array,
      default: () => [],
    },
  },

  setup() {
    const selectionCount = ref(0)

    function onSelectInvoices() {
      if (selectionCount.value) {
        const payload = {
          selectionCount: selectionCount.value,
          selectedInvoices: this.$refs.invoiceList.selectedInvoices,
        }
        this.$emit('selectInvoices', payload)
      }
    }

    return {
      selectionCount,

      onSelectInvoices,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

// Switch color
// .custom-switch .custom-control-input:not(:checked) ~ .custom-control-label:before {
//     background-color: #ea5455; // same danger color of bootstap
// }
</style>

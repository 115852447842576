import { apiInvoice } from '@/api/'

export default {
  namespaced: true,

  state: {
    ticketTypeFilter: '',
    invoiceConfig: {
      form: '1',
      serial: '',
      year: new Date().getFullYear(),
    },
  },

  mutations: {
    SET_TICKET_TYPE_FILER(state, val) {
      state.ticketTypeFilter = val
    },
    SET_INVOICE_CONFIG(state, val) {
      state.invoiceConfig = val
    },
  },

  actions: {
    async getInvoiceConfigByYear({ commit }, year) {
      const res = await apiInvoice.getInvoiceConfigByYear(year)
      if (res?.data) {
        const data = res.data
        const config = {
          form: data.form,
          serial: data.serial,
          year: data.year,
          // startSequence: data.startSequence,
          // endSequence: data.endSequence,
        }
        commit('SET_INVOICE_CONFIG', config)
      }
      return res
    },

    async fetchInvoices(_ctx, payload) {
      const res = await apiInvoice.fetchInvoices(payload)
      return res
    },

    async getInvoiceById(_ctx, { id }) {
      const res = await apiInvoice.getInvoiceById(id)
      return res
    },

    async getCompanyByTaxCode(_ctx, taxCode) {
      const res = await apiInvoice.getCompanyByTaxCode(taxCode)
      return res
    },

    async addInvoice(_ctx, payload) {
      const res = await apiInvoice.addInvoice(payload)
      return res
    },

    async updateInvoice(_ctx, { id, payload }) {
      const res = await apiInvoice.updateInvoice(id, payload)
      return res
    },

    async adjustInvoice(_ctx, { id, payload }) {
      const res = await apiInvoice.adjustInvoice(id, payload)
      return res
    },

    async replaceInvoice(_ctx, { id, payload }) {
      const res = await apiInvoice.replaceInvoice(id, payload)
      return res
    },

    async requestApproveInvoices(_ctx, payload) {
      const res = await apiInvoice.requestApproveInvoices(payload)
      return res
    },

    async refuseToApproveInvoices(_ctx, payload) {
      const res = await apiInvoice.refuseToApproveInvoices(payload)
      return res
    },

    async approveInvoices(_ctx, payload) {
      const res = await apiInvoice.approveInvoices(payload)
      return res
    },

    async refuseToSignInvoices(_ctx, payload) {
      const res = await apiInvoice.refuseToSignInvoices(payload)
      return res
    },

    async cancelInvoice(_ctx, { id, payload }) {
      const res = await apiInvoice.cancelInvoice(id, payload)
      return res
    },

    exportInvoice(ctx, payload, fileName = 'Sale-Report') {
      return apiInvoice.exportInvoice(payload, fileName)
    },

    async signInvoice(_ctx, payload) {
      const res = await apiInvoice.signInvoice(payload)
      return res
    },

    async sendMail(_ctx, payload) {
      const res = await apiInvoice.sendMail(payload)
      return res
    },

    setTicketTypeFilter({ commit }, val = '') {
      commit('SET_TICKET_TYPE_FILER', val)
    },
  },
}

import { INVOICE_STATUS_VALUES } from '@/constants/invoice'

export function canSignOrRefuseSignMultipleInvoices(invoices) {
  return invoices.every(item => this.actions.canSignOrRefuseSign(item.invoiceType, item.status))
}

export function canSendToApproveMultipleInvoices(invoices) {
  return invoices.every(item => this.actions.canSendToApprove(item.invoiceType, item.status))
}

export function canApproveOrDisapproveMultipleInvoices(invoices) {
  return invoices.every(item => this.actions.canApproveOrDisapprove(item.invoiceType, item.status))
}

export function canSendEmailMultipleInvoices(invoices) {
  return invoices.every(item => this.actions.canSendEmail(item.invoiceType, item.status))
}

export function onHandleSignMultipleInvoices(invoices = []) {
  if (!invoices.length) return

  const valid = this.canSignOrRefuseSignMultipleInvoices(invoices)
  if (valid) {
    this.useWhenSignMultipleInvoices = true
    this.setDataSendEmailMultipleInvoicesAfterSign(invoices)
    this.selectedValidInvoices = invoices
    this.showPopupInvoiceSendEmail(this.idPopupSendEmail)
  } else {
    this.$bvModal.msgBoxConfirm(this.$t('invoice.msgCanNotSignInvoices'), {
      title: this.$t('invoice.notification'),
      size: 'sm',
      hideHeaderClose: false,
      centered: true,
      footerClass: 'd-none',
      noCloseOnBackdrop: true,
    })
  }
}

export async function onSignMultipleInvoices(payload) {
  this.$bvModal.show('modal-api-loading')
  try {
    await this.$store.dispatch('app-invoice/signInvoice', payload)
    this.$bvModal.hide('modal-api-loading')
    // update status of signed invoices in invoice list
    this.selectedValidInvoices.forEach(item => {
      // Ký hoá đơn thành công => hoá đơn có trạng thái CHỜ PHÁT HÀNH
      item.status = INVOICE_STATUS_VALUES.awaitingRelease
    })
    this.showToastSuccess('Ký hóa đơn thành công')
  } catch (error) {
    this.$bvModal.hide('modal-api-loading')
    this.showToastError('Lỗi ký hoá đơn')
  }
}

export function onHandleSendEmailMultipleInvoices(invoices = []) {
  if (!invoices.length) return

  const valid = this.canSendEmailMultipleInvoices(invoices)
  if (valid) {
    this.setDataSendEmailMultipleInvoices(invoices)
    this.selectedValidInvoices = invoices
    this.showPopupInvoiceSendEmail(this.idPopupSendEmail)
  } else {
    this.$bvModal.msgBoxConfirm(this.$t('invoice.msgCanNotSendEmailInvoices'), {
      title: this.$t('invoice.notification'),
      size: 'sm',
      hideHeaderClose: false,
      centered: true,
      footerClass: 'd-none',
      noCloseOnBackdrop: true,
    })
  }
}

export function updateListViewAfterSendEmailMultipleInvoices() {
  this.selectedValidInvoices.forEach(invoice => {
    invoice.emailStatus = true
  })
}

export function onHandleRefuseSignMultipleInvoices(invoices = []) {
  if (!invoices.length) return

  const valid = this.canSignOrRefuseSignMultipleInvoices(invoices)
  if (valid) {
    this.selectedValidInvoices = invoices
    this.onRefuseSignMultipleInvoices()
  } else {
    this.$bvModal.msgBoxConfirm(
      this.$t('invoice.msgCanNotRefuseSignInvoices'),
      {
        title: this.$t('invoice.notification'),
        size: 'sm',
        hideHeaderClose: false,
        centered: true,
        footerClass: 'd-none',
        noCloseOnBackdrop: true,
      },
    )
  }
}

export async function onRefuseSignMultipleInvoices() {
  this.$bvModal.show('modal-api-loading')

  const status = INVOICE_STATUS_VALUES.refuseSignature
  const ids = this.selectedValidInvoices.map(item => Number(item.id))
  const msgErr = this.$t('invoice.msgRefuseSignErr')

  try {
    const res = await this.$store.dispatch('app-invoice/refuseToSignInvoices', {
      ids,
    })

    if (res) {
      this.selectedValidInvoices.forEach(invoice => {
        invoice.status = status
      })
      this.showToastSuccess(this.$t('invoice.msgRefuseSignSuccess'))

      return true
    }

    this.showToastError(msgErr)

    return false
  } catch (e) {
    this.showToastError(msgErr)

    return false
  } finally {
    this.$bvModal.hide('modal-api-loading')
  }
}

export function onHandleSendToApproveMultipleInvoices(invoices = []) {
  if (!invoices.length) return

  const valid = this.canSendToApproveMultipleInvoices(invoices)
  if (valid) {
    this.selectedValidInvoices = invoices
    this.onSendToApproveMultipleInvoices()
  } else {
    this.$bvModal.msgBoxConfirm(
      this.$t('invoice.msgCanNotSendToApproveInvoices'),
      {
        title: this.$t('invoice.notification'),
        size: 'sm',
        hideHeaderClose: false,
        centered: true,
        footerClass: 'd-none',
        noCloseOnBackdrop: true,
      },
    )
  }
}

export async function onSendToApproveMultipleInvoices() {
  this.$bvModal.show('modal-api-loading')

  const status = INVOICE_STATUS_VALUES.awaitingApproval
  const ids = this.selectedValidInvoices.map(item => Number(item.id))
  const msgErr = this.$t('invoice.msgSendToApproveErr')

  try {
    const res = await this.$store.dispatch(
      'app-invoice/requestApproveInvoices',
      { ids },
    )

    if (res) {
      this.selectedValidInvoices.forEach(invoice => {
        invoice.status = status
      })
      this.showToastSuccess(this.$t('invoice.msgSendToApproveSuccess'))

      return true
    }

    this.showToastError(msgErr)

    return false
  } catch (e) {
    this.showToastError(msgErr)

    return false
  } finally {
    this.$bvModal.hide('modal-api-loading')
  }
}

export function onHandleApproveMultipleInvoices(
  invoices = [],
  accepted = true,
) {
  if (!invoices.length) return

  const valid = this.canApproveOrDisapproveMultipleInvoices(invoices)
  if (valid) {
    this.selectedValidInvoices = invoices
    this.onApproveMultipleInvoices(accepted)
  } else {
    const msg = accepted
      ? this.$t('invoice.msgCanNotApproveInvoices')
      : this.$t('invoice.msgCanNotDisapproveInvoices')

    this.$bvModal.msgBoxConfirm(msg, {
      title: this.$t('invoice.notification'),
      size: 'sm',
      hideHeaderClose: false,
      centered: true,
      footerClass: 'd-none',
      noCloseOnBackdrop: true,
    })
  }
}

export async function onApproveMultipleInvoices(accepted = true) {
  this.$bvModal.show('modal-api-loading')

  const actionName = accepted
    ? 'app-invoice/approveInvoices'
    : 'app-invoice/refuseToApproveInvoices'
  const status = accepted
    ? INVOICE_STATUS_VALUES.approved
    : INVOICE_STATUS_VALUES.refuseApproval
  const ids = this.selectedValidInvoices.map(item => Number(item.id))
  const msgSucces = accepted
    ? this.$t('invoice.msgApproveSuccess')
    : this.$t('invoice.msgDisapproveSuccess')
  const msgErr = accepted
    ? this.$t('invoice.msgApproveErr')
    : this.$t('invoice.msgDisapproveErr')

  try {
    const res = await this.$store.dispatch(actionName, { ids })

    if (res) {
      this.selectedValidInvoices.forEach(invoice => {
        invoice.status = status
      })
      this.showToastSuccess(msgSucces)

      return true
    }

    this.showToastError(msgErr)

    return false
  } catch (e) {
    this.showToastError(msgErr)

    return false
  } finally {
    this.$bvModal.hide('modal-api-loading')
  }
}

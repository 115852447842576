import { ref } from '@vue/composition-api'

import { getDate } from '@core/utils/filter'

import { getSumOfTotalPayment } from '../useInvoicePayment'
import { formatCurrency, getLocaleFullName } from '../useInvoice'

export function showPopupInvoiceCancel(id = 'id-popup-cancel-invoice') {
  this.$bvModal.show(id)
}

export function hidePopupInvoiceCancel(id = 'id-popup-cancel-invoice') {
  this.$bvModal.hide(id)
}

export function getInvoiceDetailForCancel(invoice) {
  if (invoice === null) return null

  const id = invoice?.id
  if (!id) return null

  const invoiceNumber = invoice.invoiceNumber
  const formAndSerial = invoice.form + invoice.serial
  const companyName = invoice.companyName || invoice.company?.name || ''
  const createdDate = getDate(invoice.createdAt)
  const totalPayment = formatCurrency(
    getSumOfTotalPayment(invoice.invoiceItems),
  )

  let receiptNameWhenCancel = invoice.receiptNameAffterSign || ''
  if (!receiptNameWhenCancel) {
    receiptNameWhenCancel = invoice.customer
      ? getLocaleFullName(invoice.customer)
      : invoice.customerName || ''
  }

  let receiptMailWhenCancel = invoice.receiptMailWhenCancel
    ? invoice.receiptMailWhenCancel.join(';')
    : ''
  if (!receiptMailWhenCancel) {
    receiptMailWhenCancel = invoice.receiptMailAffterSign
      ? invoice.receiptMailAffterSign.join(';')
      : ''
  }
  if (!receiptMailWhenCancel) {
    receiptMailWhenCancel = invoice.customer
      ? invoice.customer.emailAddress
      : ''
  }
  if (!receiptMailWhenCancel) {
    receiptMailWhenCancel = invoice.company ? invoice.company.mail : ''
  }

  return {
    id,
    invoiceNumber,
    formAndSerial,
    companyName,
    createdDate,
    totalPayment,
    receiptNameWhenCancel,
    receiptMailWhenCancel,
  }
}

export function updateListViewAfterCancelInvoice(index) {
  this.$refs.refInvoiceListTable.localItems[index].invoiceType = 'CANCEL'
}

export function useCancelReason() {
  const cancelReason = ref('')

  return {
    cancelReason,
  }
}

export function useAutoSendEmail() {
  const isAutoSendEmail = ref(false)
  const receiptNameWhenCancel = ref(undefined)
  const receiptMailWhenCancel = ref(undefined)

  async function onValidateForm() {
    const valid = await this.$refs.refFormObserver.validate()

    if (!valid) {
      this.showToastError('Form validation failed')
      return false
    }

    return true
  }

  async function onCancelInvoice(payload) {
    this.$bvModal.show('modal-api-loading')
    try {
      const res = await this.$store.dispatch('app-invoice/cancelInvoice', {
        id: this.invoiceDetail.id,
        payload,
      })
      if (res) return true
      return false
    } catch (error) {
      return false
    }
  }

  async function onHandleFlowSignOrCancel() {
    const payload = {}
    payload.creator = JSON.parse(localStorage.getItem('employeeData'))?.id
    payload.cancelReason = this.cancelReason
    if (this.isAutoSendEmail) {
      payload.receiptNameWhenCancel = this.receiptNameWhenCancel
      // removes all whitespace characters & all trailing semicolons:
      const receiptMailWhenCancel = this.receiptMailWhenCancel
        .replace(/\s/g, '')
        .replace(/;+$/, '')
      // convert string to array:
      payload.receiptMailWhenCancel = receiptMailWhenCancel.split(';')
    }

    const isCancelled = await this.onCancelInvoice(payload)
    this.$bvModal.hide('modal-api-loading')
    this.hidePopupInvoiceCancel(this.idPopupCancel)

    if (isCancelled) {
      if (this.inDetailPage) {
        // in invoice detail page
        this.$emit('cancelInvoiceSuccess')
      } else {
        // in invoice list page
        this.$emit('cancelInvoiceSuccess', this.invoiceDetail.index)
      }
      this.showToastSuccess('Cancel invoice successfully!')
    } else {
      this.showToastError('Error cancel invoice')
    }
  }

  async function onSave(event) {
    event.preventDefault()
    const isValidForm = await this.onValidateForm()

    if (isValidForm) this.onHandleFlowSignOrCancel()
  }

  return {
    isAutoSendEmail,
    receiptNameWhenCancel,
    receiptMailWhenCancel,

    onValidateForm,
    onCancelInvoice,
    onHandleFlowSignOrCancel,
    onSave,
  }
}

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "id-invoice-popup-list",
      "size": "xl",
      "centered": "",
      "header-bg-variant": "light-info",
      "body-class": "p-1",
      "footer-class": "justify-content-between",
      "title-class": "h2 font-weight-bolder text-dark",
      "title": _vm.popupTitle,
      "ok-title": _vm.$t('invoice.continue'),
      "ok-disabled": _vm.selectionCount < 1,
      "cancel-title": _vm.$t('cancel')
    },
    on: {
      "ok": _vm.onSelectInvoices
    }
  }, [_c('InvoiceList', {
    ref: "invoiceList",
    attrs: {
      "popup-id-sufix": "popup-invoice-list",
      "enabled-actions": false,
      "fixed-invoice-status": _vm.fixedInvoiceStatus,
      "fixed-invoice-types": _vm.fixedInvoiceTypes,
      "update-selection-count": true,
      "previous-selected-ids": _vm.previousSelectedIds
    },
    on: {
      "updateSelectionCount": function updateSelectionCount(val) {
        return _vm.selectionCount = val;
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
// import VNnum2words from 'vn-num2words'

import { getDate } from '@core/utils/filter'

import {
  getTotalAmountBeforeTax,
  getTotalVat,
  getTotalCollectionFee,
} from '../useInvoicePayment'
import { getLocaleFullName, formatCurrency } from '../useInvoice'

export function showPopupPreviewInvoiceOrPdf(
  pdfUrl = null,
  idPopupPreviewInvoice = 'id-invoice-popup-preview',
  idPopupPreviewPdf = 'id-invoice-popup-preview-issue-pdf',
) {
  const id = pdfUrl ? idPopupPreviewPdf : idPopupPreviewInvoice
  this.$bvModal.show(id)
}

export function getPreviewDataFromInvoiceDetail(invoice) {
  if (!invoice) return null

  const publicDate = {
    day: '…',
    month: '…',
    year: '……',
  }
  const publicDateString = getDate(invoice.publicDate)
  if (invoice.publicDate) {
    const publicDateArr = publicDateString.split('/')
    publicDate.day = publicDateArr[0] || ''
    publicDate.month = publicDateArr[1] || ''
    publicDate.year = publicDateArr[2] || ''
    publicDate.date = publicDateString
  }

  let guestName = invoice.customerName
  if (!guestName && invoice.customer) {
    guestName = getLocaleFullName(invoice.customer)
  }

  let companyName = invoice.companyName
  if (!companyName && invoice.company) {
    companyName = invoice.company.name
  }

  const invoiceItems = invoice.invoiceItems.map(item => ({
    description: item.description,
    unit: item.unit,
    quantity: item.quantity,
    unitPrice: formatCurrency(item.unitPrice),
    beforeTaxAmount: formatCurrency(item.beforeTaxAmount),
    vatRate: item.vatRate,
    vatAmount: formatCurrency(item.vatAmount),
    amount: formatCurrency(
      Number(item.beforeTaxAmount) + Number(item.vatAmount),
    ),
  }))

  const totalAmountBeforeTax = getTotalAmountBeforeTax(invoice.invoiceItems)
  const totalVat = getTotalVat(invoice.invoiceItems)
  const totalAmount = totalAmountBeforeTax + totalVat
  const totalCollectionFee = getTotalCollectionFee(invoice.invoiceItems)
  // need to round or not ?
  // const grandTotal = Math.round(totalAmount + totalCollectionFee)
  const grandTotal = totalAmount + totalCollectionFee
  // const grandTotal2words = VNnum2words(Math.round(grandTotal))
  // const grandTotal2words = VNnum2words(grandTotal)

  return {
    pdfUrl: invoice.pdfUrl,
    invoiceNumber: invoice.invoiceNumber,
    form: invoice.form,
    serial: invoice.serial,
    publicDate,
    taCode: invoice.taCode,
    issuerName: invoice.issuerName,
    issuerTaxCode: invoice.issuerTaxCode,
    issuerAddress: invoice.issuerAddress,
    issuerPhoneNumber: invoice.issuerPhoneNumber,
    issuerBankAccountNo: invoice.issuerBankAccountNo,
    issuerBankAccountName: invoice.issuerBankAccountName,
    guestName,
    companyName,
    taxCode: invoice.taxCode,
    address: invoice.address,
    paymentMethod: invoice.paymentMethod,
    invoiceItems,
    totalAmountBeforeTax: formatCurrency(totalAmountBeforeTax),
    totalVat: formatCurrency(totalVat),
    totalAmount: formatCurrency(totalAmount),
    totalCollectionFee: formatCurrency(totalCollectionFee),
    grandTotal: formatCurrency(grandTotal),
    // grandTotal2words,
    searchCode: invoice.searchCode || '',
    status: invoice.status,
  }
}

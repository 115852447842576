import { ref, watch, computed } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'

import store from '@/store'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useInvoiceList(
  fixedInvoiceTypes = undefined,
  fixedInvoiceStatus = undefined,
) {
  const refInvoiceListTable = ref(null)
  const loading = ref(null)
  const searchKeywords = ref(null)
  const totalRows = ref(0)
  const sizePerPage = ref(10)
  const currentPage = ref(1)
  const sortBy = ref(null) // createdAt || updatedAt
  const isSortDirDesc = ref(true) //  ASC || DESC

  const invoicesList = ref([])
  const idsList = ref([])
  const selectedIds = ref([])
  const selectedInvoices = ref([])
  const idsCanSelect = ref([])
  const itemsCanSelect = ref([])
  const maxSelectionCount = computed(() => idsCanSelect.value.length)

  const dataMeta = computed(() => {
    const localItemsCount = refInvoiceListTable.value
      ? refInvoiceListTable.value.localItems.length
      : 0
    return {
      from:
        sizePerPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: sizePerPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalRows.value,
    }
  })
  const selectionCount = computed(() => selectedIds.value.length)
  const notSelect = computed(() => selectionCount.value === 0)

  const selectedValidInvoices = ref([])

  const toast = useToast()

  // filtersParams includes: searchDateBy, dateFrom, dateTo, invoiceNumber, invoiceType, invoiceStatus, emailStatus,
  let filtersParams = {}
  if (fixedInvoiceTypes) {
    filtersParams.invoiceType = fixedInvoiceTypes
  }
  if (fixedInvoiceStatus) {
    filtersParams.invoiceStatus = fixedInvoiceStatus
  }
  let isFetching = false

  function deselectInvoices() {
    selectedIds.value = []
    selectedValidInvoices.value = []
  }

  function onHandleSelectAllOrSelectNone() {
    if (this.disabledSelectAll) return

    if (this.isSelectAll) {
      selectedIds.value = []
      selectedInvoices.value = []
    } else {
      selectedIds.value = [...idsCanSelect.value]
      selectedInvoices.value = [...itemsCanSelect.value]
    }
  }

  const fetchInvoices = (_ctx, callback) => {
    loading.value = true
    deselectInvoices()
    store
      .dispatch('app-invoice/fetchInvoices', {
        ...filtersParams,
        searchText: searchKeywords.value || undefined,
        sortBy: sortBy.value ? sortBy.value : 'createdAt',
        sortDirection: isSortDirDesc.value ? 'DESC' : 'ASC',
        size: sizePerPage.value,
        page: currentPage.value,
      })
      .then(res => {
        totalRows.value = res.total
        invoicesList.value = res.items
        idsList.value = invoicesList.value.map(item => item.id)
        selectedIds.value = []
        selectedInvoices.value = []
        callback(invoicesList.value)
      })
      .catch(error => {
        console.error('error', error)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching invoices list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      .finally(() => {
        loading.value = false
        isFetching = false
      })
  }

  const exportInvoice = () => {
    store
      .dispatch('app-invoice/exportInvoice', {
        ...filtersParams,
        searchText: searchKeywords.value || undefined,
        sortBy: sortBy.value ? sortBy.value : 'createdAt',
        sortDirection: isSortDirDesc.value ? 'DESC' : 'ASC',
        size: sizePerPage.value,
        page: currentPage.value,
      })
      .then(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Export successfully',
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        })
      })
      .catch(error => {
        console.error({ error })
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error export',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const refetchData = ($event = {}, page = 1) => {
    isFetching = true
    filtersParams = { ...$event }
    if (fixedInvoiceTypes) {
      filtersParams.invoiceType = fixedInvoiceTypes
    }
    if (fixedInvoiceStatus) {
      filtersParams.invoiceStatus = fixedInvoiceStatus
    }
    currentPage.value = page
    refInvoiceListTable.value.refresh()
  }
  const clearFilter = () => {
    searchKeywords.value = null
    refetchData()
  }
  const isChecked = id => selectedIds.value.includes(id)
  const setArray = (array, id) => {
    const index = array ? array.findIndex(element => element === id) : -1
    // eslint-disable-next-line no-unused-expressions
    index >= 0 ? array.splice(index, 1) : array.push(id)

    return array
  }

  function toggleSelectInvoice(item) {
    const index = selectedInvoices.value
      ? selectedInvoices.value.findIndex(elm => elm.id === item.id)
      : -1
    // eslint-disable-next-line no-unused-expressions
    index >= 0
      ? selectedInvoices.value.splice(index, 1)
      : selectedInvoices.value.push(item)
  }
  const toggleSelectItem = item => {
    toggleSelectInvoice(item)
    selectedIds.value = setArray(selectedIds.value, item.id)
  }

  watch([currentPage, sizePerPage, sortBy, isSortDirDesc], () => {
    if (!isFetching) {
      refetchData(filtersParams, currentPage.value)
    }
  })

  return {
    refInvoiceListTable,
    loading,
    searchKeywords,
    totalRows,
    sizePerPage,
    currentPage,
    sortBy,
    isSortDirDesc,
    dataMeta,
    invoicesList,
    idsList,
    selectedIds,
    idsCanSelect,
    itemsCanSelect,
    maxSelectionCount,
    selectedInvoices,
    selectedValidInvoices,
    selectionCount,
    notSelect,

    onHandleSelectAllOrSelectNone,
    fetchInvoices,
    refetchData,
    clearFilter,
    isChecked,
    toggleSelectItem,
    exportInvoice,
  }
}

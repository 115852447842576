export function getSumOfTotalPayment(invoiceItems) {
  return invoiceItems.reduce((acc, item) => acc + Number(item.totalPayment), 0)
}

export function getTotalAmountBeforeTax(invoiceItems) {
  return (
    invoiceItems
      // NOTE: comment out filter due to no more invoice item characteristic in updated SRS
      // .filter(item => !item.characteristic || item.characteristic === TYPES.goods)
      .reduce((acc, item) => acc + Number(item.beforeTaxAmount), 0)
  )
}

export function getTotalVat(invoiceItems) {
  return (
    invoiceItems
      // NOTE: comment out filter due to no more invoice item characteristic in updated SRS
      // .filter(item => !item.characteristic || item.characteristic === TYPES.goods)
      .reduce((acc, item) => acc + Number(item.vatAmount), 0)
  )
}

export function getTotalCollectionFee(invoiceItems) {
  return invoiceItems.reduce((acc, item) => acc + Number(item.collectionFee), 0)
}
